<template>
    <div class="guarantee-look">
        <backHeader C_Title='guaranteeSlip'></backHeader>
        <div class="guarantee-look-list">
            <div>
                <h3>保单查看</h3>
                <div>
                    
                    <el-button type="warning" @click="correct()" :disabled="type!=1">我要增加/替换人员</el-button>
                    <a :href="baseUrl+bill_url" :style="bill_url?'':'pointer-events:none'" target="_blank" style="display: inline-block;margin: 0 20px;"><el-button :disabled="bill_url==''?true:false" type="danger">发票下载</el-button></a>
                    <a :href="baseUrl+policy_url" :style="policy_url?'':'pointer-events:none'" target="_blank"><el-button :disabled="policy_url==''?true:false" type="primary">电子保单下载</el-button></a>
                </div>
            </div>
            <div class="guarantee-look-list-2">
                <div>
                    <img src="../../image/guaranteeLook/1.png" alt="" v-if="type==1">
                    <img src="../../image/guaranteeLook/4.png" alt="" v-if="type==4">
                    <img src="../../image/guaranteeLook/5.png" alt="" v-if="type==5">
                </div>
                <div>
                    <div>保单号：{{policy_num}}</div>
                    <div>
                        <div>流水号：{{num_id}}</div>
                        <div>创建时间：{{c_time}}</div>
                        <div> 保障期限：{{s_time}}至 {{e_time}}</div>
                    </div>
                    <div>保费 <span>¥{{policy_money}}</span></div>
                </div>
                <div>
                    <div>
                        <div>产品名称：{{product_name}}</div>
                        <div>身故残疾：{{Safeguard[0]}}</div>
                    </div>
                    <div>
                        <div>保险公司：{{company_name}}</div>
                        <div>意外医疗：{{Safeguard[1]}}</div>
                    </div>
                    <div>
                        <div>职位类别：{{job_name}}</div>
                        <div>误工费：{{Safeguard[2]}}</div>
                    </div>
                    <div>
                        <div>工种：{{work_name}}</div>
                    </div>
                    <div>
                        <div>扩展方案：</div>
                    </div>
                </div>
            </div>
            <div class="guarantee-look-list-3">
                <div>
                    <h4>发票信息</h4>
                    <div>
                        <!-- <div>是否需要开发票：是</div> -->
                        <div style="pointer-events:none;">发票类型：
                            <el-radio v-model="invoice.type" label="1">普票</el-radio>
                            <el-radio v-model="invoice.type" label="2">专票</el-radio>
                        </div>
                        <div>纳税人名称：<span>{{invoice.taxpayer_name}}</span></div>
                        <div>纳税人识别号：<span>{{invoice.taxpayer_id}}</span></div>
                    </div>
                </div>
            </div>
            <div class="guarantee-look-list-4">
                <div>
                    <div :class="chooseTitle==1?'chooseIt':''" @click="toggle(1)">在保人员</div>
                    <div :class="chooseTitle==2?'chooseIt':''" @click="toggle(2)">待生效人员</div>
                    <div :class="chooseTitle==3?'chooseIt':''" @click="toggle(3)">过保人员</div>
                </div>
            </div>
            <div class="guarantee-look-list-5">
                <div>
                    <div>
                        <div>
                            <div>姓名：</div>
                            <el-input v-model="searchName" placeholder="请输入内容"></el-input>
                            <div>身份证号：</div>
                            <el-input v-model="searchIdCard" placeholder="请输入内容"></el-input>
                        </div>
                        <div>
                            <el-button type="primary" @click="search">查询</el-button>
                            <el-button @click="reset()">重置</el-button>
                        </div>
                    </div>
                    <div>
                        <el-table
                                align="center"
                                :data="tableData"
                                border
                                style="width: 100%">
                            <el-table-column
                                    align="center"
                                    prop="id"
                                    width="80px"
                                    type="index"
                                    label="序号">
                            </el-table-column>
                            <el-table-column
                                    align="center"
                                    prop="name"
                                    label="姓名">
                            </el-table-column>
                            <el-table-column
                                    align="center"
                                    prop="paper_num"
                                    label="身份证号">
                            </el-table-column>
                            <el-table-column
                                    align="center"
                                    prop="job_name"
                                    label="工作种类">
                            </el-table-column>
                            <el-table-column
                                    align="center"
                                    prop="money"
                                    label="保费金额">
                            </el-table-column>
                            <el-table-column
                                    align="center"
                                    prop="time"
                                    label="保费期限">
                                    <template slot-scope="scope">
                                        <div>
                                            <span>{{scope.row.s_time}} 至 {{scope.row.e_time}}</span>
                                        </div>
                                    </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import backHeader from "../../components/back/backHeader";

    export default {
        name: "guaranteeLook",
        components: {
            backHeader
        },
        data() {
            return {
                baseUrl:"",
                product_name:"",//产品名
                num_id:"",//流水号
                policy_num:"",//保单号
                Safeguard:"",//保障
                c_time:"",//创建时间
                s_time:"",//开始时间
                e_time:"",//终止时间
                policy_money:"",//保费
                company_name:'',//保险公司
                job_name:"",// 职位类别
                work_name:"",//工种
                type:'',//订单状态
                // 扩展方案
                invoice:{
                   taxpayer_id:"",//
                   taxpayer_name:'',//
                   type:'',//发票 1普 2专
                },
                policy_url:"",//保单
                bill_url:"",//发票
                nowData:"",//在保人员
                futureData:"",//待生效人员
                pastData:"",//过保人员
                tableData: [],//表格
                chooseTitle:1,
                searchName:'',//姓名搜索用
                searchIdCard:''//id搜索用
            }
        },
        created() {
            this.num_id = this.$route.query.num_id
            this.baseUrl=this.$store.state.baseUrl
        },
        mounted() {
            this.getPcOrderDetail()
        },
        methods: {
            // 加载数据
            getPcOrderDetail:function(){
                let that=this;
                let loading=that.$loading({
                    lock: true,
                    text: 'Loading',
                    background: 'rgba(0, 0, 0, 0.5)'
                });
                that.axios({
                    url: that.$store.state.baseUrl + '/api/product/order/getPcOrderDetail',
                    method: 'POST',
                    params:{
                        num_id:that.num_id,
                        // num_id:2003120487897
                    }
                }).then(function(res) {
                    console.log(res)
                    loading.close();
                    if(res.data.code==200){
                        that.product_name=res.data.data.product_name,//产品名
                        that.policy_num=res.data.data.policy_num,//保单号
                        that.Safeguard=res.data.data.Safeguard,//保障
                        that.c_time=res.data.data.c_time,//创建时间
                        that.s_time=res.data.data.s_time,//开始时间
                        that.e_time=res.data.data.e_time,//终止时间
                        that.policy_money=res.data.data.policy_money,//保费
                        that.company_name=res.data.data.company_name,//保险公司
                        that.job_name=res.data.data.job_name,// 职位类别
                        that.work_name=res.data.data.work_name,//工种
                        that.type=res.data.data.type,//订单状态
                        that.invoice.taxpayer_id=res.data.data.invoice.taxpayer_id,//
                        that.invoice.taxpayer_name=res.data.data.invoice.taxpayer_name,//
                        that.invoice.type=res.data.data.invoice.type+'',//发票 1普 2专
                        that.policy_url=res.data.data.policy_url//保单
                        that.bill_url=res.data.data.bill_url//发票文件
                        that.nowData=res.data.data.nowData,//在保人员
                        that.futureData=res.data.data.futureData,//待生效人员
                        that.pastData=res.data.data.pastData//过保人员
                        that.tableData=res.data.data.nowData
                    }else{
                        that.$message.error("加载失败请稍后重试");
                    }              
                }).catch(function (error) {
                    console.log(error);
                });   
            },
            // 切换保障人员
            toggle:function(e){
                this.chooseTitle=e
                if(e==1){this.tableData=this.nowData
                }else if(e==2){this.tableData=this.futureData
                }else{ this.tableData=this.pastData
                }
            },
            //搜索
            search:function(){
                let that=this;
                let loading=that.$loading({
                    lock: true,
                    text: 'Loading',
                    background: 'rgba(0, 0, 0, 0.5)'
                });
                that.axios({
                    url: that.$store.state.baseUrl + '/api/product/order/searchEmployee',
                    method: 'POST',
                    params:{
                        num_id:that.num_id,
                        // num_id:2003120487897,
                        type:that.chooseTitle,                
                        name:that.searchName,
                        paper_num:that.searchIdCard
                    }
                }).then(function(res) {
                    console.log(res)
                    loading.close();
                    if(res.data.code==200){
                       that.tableData=res.data.data
                    }else{
                        that.$message.error("加载失败请稍后重试");
                    }              
                }).catch(function (error) {
                    console.log(error);
                });   
            },
            // 重置
            reset:function(){
                this.searchName="",
                this.searchIdCard=""
                if(this.chooseTitle==1){this.tableData=this.nowData
                }else if(this.chooseTitle==2){this.tableData=this.futureData
                }else{ this.tableData=this.pastData
                }
            },
            // 申请批改
            correct: function () {
                this.$router.push({
                    name: 'applyCorrect',
                    query: {
                        num_id:this.num_id
                        // num_id:2003120487897,
                    }
                })
            },
        }
    }
</script>

<style scoped lang="scss">
    .guarantee-look {
        // position: fixed;
        top: 0;
        bottom: 0;
        width: 100%;
        background: #F4F3F6;
        overflow-y: scroll;
        padding-top:70px
    }

    .guarantee-look-list {
        width: 70%;
        margin-left: 15%;

        & > div:nth-child(1) {
            display: flex;
            width: 100%;
            justify-content: space-between;
            background: #F3F5FB;
            align-items: center;
            box-sizing: border-box;
            padding: 10px 20px;

            & > h3 {
                padding: 0;
                margin: 0;
            }

            & > div:nth-child(2) {
                display: flex;
                justify-content: flex-end;
            }
        }

        .guarantee-look-list-2 {
            display: flex;
            justify-content: flex-start;
            box-sizing: border-box;
            padding: 20px;
            background: white;

            & > div:nth-child(1) {
                width: 15%;
            }

            & > div:nth-child(2) {
                & > div {
                    width: 100%;
                    text-align: left;
                }

                & > div:nth-child(1) {
                    font-size: 24px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: rgba(51, 51, 51, 1);
                    line-height: 54px;
                }

                & > div:nth-child(2) {
                    font-size: 14px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: rgba(101, 101, 101, 1);
                    line-height: 30px;
                }

                & > div:nth-child(3) {
                    font-size: 14px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: rgba(101, 101, 101, 1);
                    line-height: 30px;
                    & > span {
                        color: #E93D39;
                        font-size: 24px;
                    }
                }

                width: 50%;
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
            }

            & > div:nth-child(3) {
                margin-top: 40px;
                width: 50%;
                font-size: 14px;
                font-family: PingFang SC;
                font-weight: 400;
                color: rgba(101, 101, 101, 1);
                line-height: 30px;
                & > div {
                    display: flex;
                    justify-content: space-between;

                    & > div:nth-child(2) {
                        width: 40%;
                        text-align: left;
                    }
                }
            }
        }

        .guarantee-look-list-3 {
            display: flex;
            justify-content: flex-start;
            background: white;
            & > div {
                & > h4 {
                    font-size: 18px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: rgba(51, 51, 51, 1);
                    line-height: 34px;
                }

                & > div {
                    font-size: 14px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: rgba(101, 101, 101, 1);
                    line-height: 30px;
                }

                width: 100%;
                border-top: 1px solid rgba(243, 243, 245, 1);
                margin: 40px 20px 40px 20px;
                padding-left: 15%;
                text-align: left;
            }
        }

        .guarantee-look-list-4 {
            width: 100%;
            background: white;
            height: 48px;
            & > div:nth-child(1) {
                margin: 0 20px;
                background: rgba(243, 243, 245, 1);
                display: flex;
                & > div {
                    width: 150px;
                    text-align: center;
                    line-height: 48px;
                    font-size: 18px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: rgba(51, 51, 51, 1);
                    cursor: pointer;
                }

                .chooseIt {
                    color: #0053B9;
                    border-bottom: 2px solid #0053B9;
                }
            ;
            }
        }

        .guarantee-look-list-5 {
            width: 100%;
            background: white;
            padding-bottom: 40px;
            & > div {
                padding: 20px;
                & > div:nth-child(1) {
                    display: flex;
                    justify-content: space-between;

                    & > div {
                        margin-top: 20px;
                        margin-bottom: 20px;
                        display: flex;
                        align-items: baseline;

                        & > div:nth-child(1) {
                            width: 150px;
                        }

                        & > div:nth-child(3) {
                            margin-left: 15px;
                            width: 210px;
                        }
                    }
                }
            }
        }
    }
</style>